import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

const Seo = ({
  description,
  lang,
  meta,
  title = "Kamiko",
  url = "/",
  imageSrc,
  jsonLdData,
}) => {
  const { site, defaultImage } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        defaultImage: file(name: { eq: "homepage" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              height: 630
              placeholder: BLURRED
              layout: FIXED
            )
          }
        }
      }
    `
  )

  description = description || site.siteMetadata.description

  imageSrc = imageSrc || getSrc(defaultImage.childImageSharp.gatsbyImageData)
  const ogImageSrc = site.siteMetadata.siteUrl + imageSrc

  const ogUrl = site.siteMetadata.siteUrl + url

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      defaultTitle={site.siteMetadata.title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: ogImageSrc,
        },
        {
          property: `og:image:width`,
          content: 1200,
        },
        {
          property: `og:image:height`,
          content: 630,
        },
        {
          property: `og:image:alt`,
          content: description,
        },
        {
          property: `og:url`,
          content: ogUrl,
        },
        {
          property: `twitter:image`,
          content: ogImageSrc,
        },
        {
          property: `image`,
          content: ogImageSrc,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat(meta)}
    >
      {jsonLdData &&
        jsonLdData.map(ldData => (
          <script type="application/ld+json">{JSON.stringify(ldData)}</script>
        ))}
      <script
        id="cookieyes"
        type="text/javascript"
        src="https://cdn-cookieyes.com/client_data/bfacb7ae054d00355861a3a4/script.js"
      ></script>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `pl-PL`,
  meta: [],
  description: ``,
  title: "Kamiko",
  url: "/",
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  imageSrc: PropTypes.string,
}

export default Seo
